import { datadogLogs } from '@datadog/browser-logs'

export const pageview = (url: string) => {
  const gtmPropertyId = process.env.NEXT_PUBLIC_GTM_PROPERTY_ID
  if (!gtmPropertyId) return

  window.gtag('config', gtmPropertyId, {
    page_path: url,
  })
}

// log specific events happening.
export const gaEvent = ({ action, params }: { action: string; params?: any }) => {
  try {
    window.gtag('event', action, { ...params, event_category: 'Diaper Fund' })
  } catch (error) {
    datadogLogs.logger.error(`Problem logging event to GA`, { error })
    console.error(error)
  }
}
